import React from 'react'

import { List, ListItem, Paragraph, Strong, Text } from '@te-digi/styleguide'

import { Code } from '../components/Code'
import { CodeBox } from '../components/CodeBox'
import { Content } from '../components/Content'
import { Section } from '../components/Section'
import { SectionHeading } from '../components/SectionHeading'
import { Link } from '../components/Link'

const Page = () => (
  <Content heading="Käyttöönotto">
    <Section title="Fontti">
      <Paragraph>
        Style Guide käyttää{' '}
        <a
          href="https://fonts.google.com/specimen/Cabin?selection.family=Cabin:400,600"
          rel="noopener noreferrer"
          target="_blank"
        >
          Cabin
        </a>
        -fontin <Text fontWeight="semi-bold">normal</Text> (400) sekä{' '}
        <Text fontWeight="semi-bold">semi-bold</Text> (600) variantteja. Fontit
        ovat mukana paketissa.
      </Paragraph>
      <CodeBox language="html">
        {`
<head>
  <style>
    @font-face {
      font-display: swap;
      font-family: 'Cabin';
      font-style: normal;
      font-weight: 400;
      src: local('Cabin-Regular'), url('/path/to/cabin-regular.woff2') format('woff2');
    }

    @font-face {
      font-display: swap;
      font-family: 'Cabin';
      font-style: normal;
      font-weight: 600;
      src: local('Cabin-SemiBold'), url('/path/to/cabin-semibold.woff2') format('woff2');
    }
  </style>
</head>
        `}
      </CodeBox>
    </Section>
    <Section title="React-sovellukset">
      <SectionHeading>Riippuvuuksien asennus</SectionHeading>
      <CodeBox>npm install @te-digi/styleguide styled-components</CodeBox>
      <SectionHeading>
        <Code>Provider</Code>-komponentin käyttöönotto
      </SectionHeading>
      <Paragraph>
        Sovellus tulee olla{' '}
        <Code>
          <Link page="Provider" />
        </Code>
        -komponentin sisässä ja sille on tulee määritellä käytössä oleva kieli (
        <Code>en</Code>, <Code>fi</Code> tai <Code>sv</Code>).
      </Paragraph>
      <CodeBox language="tsx">
        {`
import { Provider } from '@te-digi/styleguide'

return (
  <Provider locale={locale}>
    <App />
  </Provider>
)
        `}
      </CodeBox>
      <SectionHeading>Tyylit</SectionHeading>
      <Paragraph>
        Style Guide React-komponentit käyttävät <Code>styled-components</Code>{' '}
        -kirjastoa tyyleille.
      </Paragraph>
      <Paragraph>
        Verkkopalveluun upotetuissa sovelluksissa (widgeteissä) ei tule käyttää
        Verkkopalvelu-kirjaston tarjoamia CSS-luokkia. Yhteentörmäysten
        välttämiseksi on suositeltavaa nimetä luokat sovelluskohtaisella
        etuliitteellä tai käyttää <Code>styled-components</Code>{' '}
        &#8209;kirjastoa.
      </Paragraph>
      <Paragraph>
        Itsenäisille React-sovelluksille, jotka <Strong>eivät ole</Strong>{' '}
        Verkkopalvelussa widgetteinä käytössä, Style Guide tarjoaa{' '}
        <Code>
          <Link page="CSSReset" />
        </Code>
        -komponentin, joka resetoi HTML-elementtien tyylit.
      </Paragraph>
      <CodeBox language="tsx">
        {`
import { CSSReset, Provider } from '@te-digi/styleguide'

return (
  <Provider locale={locale}>
    <CSSReset /> {/* Do not use with widgets! */}
    <App />
  </Provider>
)
        `}
      </CodeBox>
      <SectionHeading>Komponenttien käyttöönotto</SectionHeading>
      <CodeBox language="tsx">
        {"import { Button } from '@te-digi/styleguide'"}
      </CodeBox>
    </Section>
    <Section title="Browserslist">
      <Paragraph>
        Style Guide tarjoaa yhteisen browserslist-konfiguraation front-end
        työkaluille.
      </Paragraph>
      <CodeBox>npm install @te-digi/browserslist-config</CodeBox>
      <Paragraph>
        Käyttöönotto tapahtuu lisäämällä seuraava rivi esim.{' '}
        <Code>.browserslistrc</Code>-tiedostoon:
      </Paragraph>
      <CodeBox>extends @te-digi/browserslist-config</CodeBox>
    </Section>
    <Section title="Verkkopalvelu">
      <Paragraph>
        Style Guide tarjoaa kaksi tiedostoa Työmarkkinatorin web-sivustolle:
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          <Paragraph>
            <Code>styles.css</Code>: CSS resetointi, HTML-komponentit ja
            utility-luokat. Tyylejä voi käyttää React-widgettien
            kehitysympäristössä jäljittelemään Verkkopalvelun sivupohjia, mutta{' '}
            <Strong>ei</Strong> itse sovelluksissa. Lisää{' '}
            <Code>{'<head>'}</Code>-elementin sisään:
          </Paragraph>
          <CodeBox language="html">
            {
              '<link href="/path/to/styles.css" rel="stylesheet" type="text/css" />'
            }
          </CodeBox>
        </ListItem>
        <ListItem>
          <Paragraph>
            <Code>global.js</Code>: HTML-komponenttien toiminallisuudet. Lisää
            juuri ennen <Code>{'</body>'}</Code>-elementtiä.
          </Paragraph>
          <CodeBox language="html">
            {`
<script src="/path/to/global.js"></script>
<script>
  // Initialize Style Guide JS-library
  StyleGuide.initialize()

  // Initialize Terms-component
  StyleGuide.initializeTerms()
</script>
            `}
          </CodeBox>
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
